.featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  }
  
  /* Thin out the marketing headings */
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
  }

  .featurette-container{
      margin-left: 2rem;
      margin-right: 2rem;
  }

  .parts-img{ 
      width: 60vh;
      height: auto;
      background-color: transparent;
      -webkit-animation:spin 30s linear infinite;
      -moz-animation:spin 30s linear infinite;
      animation:spin 30s linear infinite;
  }

  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}


.car-img{ 
   
    width: 80vh;
    height: auto;
    background-color: transparent;
}

@media(max-width:40em){
    .featurette-container{
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
  justify-content: center;
    }
    .parts-img{
        width: 40vh;
        margin: auto;
    }

    .img-container{
        display: flex;
  justify-content: center;
    }
    .car-img{
        width: 40vh;
        margin: auto;
        margin-bottom: 4vh;
    }

    .featurette-divider{
        margin: 2rem 0;
    }
}