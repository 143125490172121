.home {
  background-color: rgb(248, 248, 248);
  /* height: 100vh; */
  margin: 0;
}
html,
body,
#app,
#app > div {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}

.carousel-container{
  margin: 0;
}

.slider-image{
height: 60vh;
width: 80%;
margin: auto;
background-size:cover;

}

.tagline{
  background:rgba(0, 0, 0, 0.6);
  height: 100%;
  color: white;
  font-size: 50px;
  
}

.tagline p{
  margin: auto;
  width: 50%;
  display:block;
  text-align: center;
  padding: 25vh 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
}

@media (max-width:40em){
  .tagline p {
    vertical-align: middle;
    font-size: 1.25rem;
    text-align: center;
    padding: 50% 0;
  }
}