#root{
  box-sizing: border-box;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* About page */

div#ma-top {
  background: #bbbbbb30;
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1em;
  position: relative;
}


#ma-top img {
  position: absolute;
  width: 16rem;
  left: 31rem;
  opacity: 0.8;
}


div#ma-top h1 {
  font-family: monospace;
  font-weight: bold;
  font-size: 2.5rem;
}

div#ma-top h3 {
  font-size: 1rem;
  font-weight: lighter;
  font-family: monospace;
}

div#about-text {
  margin-top: 3em;
  padding-right: 1em;
  padding-left: 1em;
  text-align: center;
}

div#about-text h1{
  font-size: 2rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

div#about-text h2{
    color: red;
    font-weight: lighter;
    font-family: sans-serif;
    font-size: 2.4rem;
}

div#about-text p {
  font-size: 1rem;
}

div#banner-img {
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #b7b4b4;
}

#banner-img img {
  width: 13rem;
  margin: auto;
  display: block;
  margin-bottom: 2rem;
}


@media screen and (min-width: 768px) {
  div#ma-top {
    font-size: 3rem;
  }
  div#about-text{
    font-size: 2rem;
  }
}

@media screen and (min-width: 991px) {
  div#ma-top{
    height: 24rem;
    font-size: 4rem;
  }

  div#ma-top h1{
    font-size: 3.5rem;
  }

  div#ma-top h3{
    font-size: 1.2rem;
  }

  div#ma-top img{
    width: 26rem;
    left: 55rem;
  }

  div#about-text h2{
    font-size: 3.4rem;
  }
  
  div#about-text h1{
    font-size: 2.6rem;
  }

  div#about-text p {
    font-size: 1.4rem;
    width: 87%;
    margin: auto;
    padding: 1.1em;
  }

  div#banner-img {
    padding-top: 4em;
    padding-bottom: 4em;
    display: flex;
    justify-content: space-evenly;
    margin-top: 3em;
  }

  #banner-img img {
    width: 21rem;
    margin-bottom: auto ;
  }
}


/* Contact Us */
div#contact-details {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

div#contact-details .cd-item{
  background: url('./assets/contact_bg.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 240px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2rem;
}

.cd-item img{
  position: absolute;
  top: 30px;
  width: 62px;
}

.cd-text{
  background-color: #f8f8f8;
  text-align: center;
  padding-top: 55px;
  padding-bottom: 20px;
  padding-left: 0;
  width: 100%;
}

.cd-text h1 {
  font-size: 1.4rem;
  margin: 0;
  margin-bottom: 0.5em;
}

.cd-text p {
  margin: 0;
}

#send-messages {
  padding: 1em;
}

#send-messages img {
  width: 100%;
  /* height: 3rem !important; */
}

#send-messages form{
  padding: 1em;
  padding: 1.8em;
  background: #eeeef7;
}

#send-messages h3 {
    margin: 0;
    padding-bottom: 1.4em;
}

div#inpu-fields-form {
  display: flex;
  flex-direction: column;
  height: 7rem;
  justify-content: space-between;
  padding-right: 2rem;
  /* border: none; */
}

#send-messages input {
  border: 1px solid #dfd1d1;
  padding: 0.5em;
  padding-left: 1em;  
}

#send-messages textarea{
    border: 1px solid #dfd1d1;
    margin-top: 2em;
    width: 86%;
    margin-bottom: 2em;
    padding: 0.5em;
    padding-left: 1em;
}

#send-messages button{
    border: 0px black solid;
    background: #ee3231;
    color: white;
    /* width: 6.8rem; */
    /* height: 2.4rem; */
    padding: 0.5em;
    /* padding-bottom: 1em; */
    padding-left: 1.6em;
    padding-right: 1.6em;
}

@media screen and (min-width: 768px) {
  div#contact-details {
    padding-top: 5em;
    padding-bottom: 3rem;
    display: flex;
  }

  div#contact-details .cd-item {
    flex: 1 1 50%;
  }

  #send-messages {
    padding: 1em;
    display: flex;
  }

  #send-messages img {
    width: 50%;
    /* height: 3rem !important; */
    flex: 1 1 50%;
    display: block;
  }

  #send-messages form {
    padding: 1em;
    padding: 1.8em;
    background: #eeeef7;
    flex: 1 1 50%;
  }
}

@media screen and (min-width: 991px) {
  #send-messages {
    padding: 1em;
    display: flex;
    width: 90%;
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}