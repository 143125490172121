.navbar {
  background-color: rgb(255, 255, 255);
  height: 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  outline: none;
  box-shadow: 4px, 5px, black;
}

.navbar a {
  display: inline-block;
  margin-inline: 10px;
  position: relative;
}

.navbar a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff0000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.navbar a:link {
  text-decoration: none;
  color: black;
}

.navbar a:visited {
  text-decoration: none;
  color: black;
}

.icon-logo{
  background-size: cover;
  height: 50px;
    width: 50px;
    display: inline-block;
    outline: none;
}